
import ToolsMixin from '@/mixins/ToolsMixin';
import CurrentTab from '@/components/Tools/Billing/CurrentTab';
import HistoryTab from '@/components/Tools/Billing/HistoryTab';

export default {
  components: {
    CurrentTab,
    HistoryTab
  },
  mixins: [ToolsMixin],
  data: () => ({
    activeTab: 'CurrentTab'
  })
};
